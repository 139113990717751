<template>
	<div class="notice-wrapper">
		<v-container>
			<div class="notice">
				<div class="notice-title">
					<div class="link">
						<span>{{ notice.title }}</span>
						<span class="sub-date">{{ notice.createdAt | dateFormat }}</span>
					</div>
					<div class="date">{{ notice.createdAt | dateFormat }}</div>
				</div>
				<div class="notice-contents">
					<template v-if="notice.id === noticeAlessiContestNoticeId">
						<notice-alessi-contest-detail />
					</template>
					<template v-else>
						<div v-html="notice.content"></div>
					</template>
				</div>
			</div>
			<router-link class="route-to-list" :to="{ name: 'notices' }">
				목록
			</router-link>
		</v-container>
	</div>
</template>
<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import { ref } from '@vue/composition-api'

import NoticeService from '@/services/NoticeService'

import NoticeAlessiContestDetail from './NoticeAlessiContestDetail.vue'

export default {
	components: {
		NoticeAlessiContestDetail,
	},
	setup() {
		const notice = ref([])
		const { route } = useRouter()
		const noticeAlessiContestNoticeId = ref(15)

		const getNotice = async () => {
			try {
				notice.value = await NoticeService.getNoticeDetail(
					route.value.params.noticeId,
				)
			} catch (e) {
				console.error(e)
			}
		}
		getNotice()

		store.dispatch('app/setIsLogoWhite', false)

		return {
			notice,
			noticeAlessiContestNoticeId,
		}
	},
}
</script>
<style lang="scss" scoped>
.notice-wrapper {
	padding-top: 100px;

	a.route-to-list {
		display: block;
		width: 110px;
		margin: 40px auto 0;
		height: 44px;
		line-height: 44px;
		text-decoration: none;
		color: white;
		background-color: #0e0d49;
		text-align: center;
	}

	.notice {
		&-title {
			display: table;
			table-layout: fixed;
			width: 100%;
			min-height: 100px;
			padding: 25px 0;
			box-sizing: border-box;
			background-color: #fff;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;

			.link {
				font-size: 17px;
				line-height: 25px;
				display: table-cell;
				width: 80%;
				padding: 0 40px;
				color: #333;
				vertical-align: middle;

				@media (max-width: 1040px) {
					padding: 0 17px;
				}
			}
			.date {
				display: table-cell;
				width: 240px;
				font-size: 18px;
				font-weight: 400;
				line-height: 22px;
				color: #999;
				text-align: center;
				text-transform: uppercase;
				vertical-align: middle;

				@media (max-width: 1040px) {
					display: none;
				}
			}

			.sub-date {
				display: none;
				font-size: 18px;
				line-height: 22px;
				color: #999;

				@media (max-width: 1040px) {
					display: block !important;
				}
			}
		}

		&-contents {
			overflow: hidden;
			margin-top: 2px;
			padding: 29px 68px 68px;
			border: 1px solid #e3e5ec;
			background-color: #f9fafc;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;

			h3 {
				font-size: 16px;
			}

			p {
				margin: 1em 0;
			}

			img {
				width: 100%;
			}

			@media (max-width: 1040px) {
				padding: 20px 17px;
			}
		}
	}
}
</style>
